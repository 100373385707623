<template>
  <div class="body">
    <div class="card">
      <div class="block">
        <div style="display: flex" class="title">
          <div class="left">搜索相关</div>
          <el-tooltip class="item" effect="light" placement="top">
            <div slot="content">
              建议先浏览 文章
              <a href="https://zhuanlan.zhihu.com/p/231936440" target="_blank"
                >前端web页面调起本地客户端exe</a
              >
              后设置本地文件 使用次操作
            </div>
            <el-button style="margin-left: 40px" type="danger" @click="climb()"
              >爬！！！</el-button
            >
          </el-tooltip>
        </div>
        <div class="left">
          <el-radio v-model="radio" label="1">百度</el-radio>
          <el-radio v-model="radio" label="2">谷歌</el-radio>
        </div>
        <div style="margin-top: 20px; display: flex">
          <el-input
            v-model="input"
            placeholder="搜索"
            style="width: 20rem"
            @keyup.enter.native="search"
          ></el-input>
          <el-button style="margin-left: 40px" type="primary" @click="search()"
            >搜索</el-button
          >
        </div>
      </div>

      <div class="block" style="margin-top: 20px">
        <!-- <div style="display: flex" class="title">
          <div class="left">网页跳转</div>
        </div> -->
        <div style="margin-top: 20px; display: flex">
          <el-input
            v-model="link"
            placeholder="网页跳转"
            style="width: 20rem"
            @keyup.enter.native="jump"
          ></el-input>
          <el-button style="margin-left: 40px" type="primary" @click="jump()"
            >跳转</el-button
          >
        </div>
      </div>
      <div class="block">
        <div style="display: flex" class="title">
          <div class="left">网盘跳转</div>
        </div>
        <div class="left">
          <el-tag class="baiduJump">https://pan.baidu.com/</el-tag>
          <el-tag class="baiduJump" @click="change()" :type="type"> s/ </el-tag>
          <el-input
            style="width: 15rem"
            v-model="baidulink"
            class="baiduJump"
            placeholder="请输入内容"
          ></el-input>
          <el-input
            style="width: 15rem"
            class="baiduJump"
            placeholder="请输入内容"
            v-model="psw"
          >
            <template slot="prepend">psw=</template>
          </el-input>
          <el-button
            style="margin-left: 20px"
            type="primary"
            @click="baiduPanJump()"
            >百度网盘</el-button
          >
        </div>
      </div>

      <div class="block">
        <div style="display: flex" class="title">
          <div class="left">粘贴板</div>
          <div class="saveButton" style="margin-left: 40px">
            <el-button type="primary" @click="save()">保存</el-button>
          </div>
          <div class="saveButton">
            <el-button type="primary" @click="clearLeft()">清空左</el-button>
          </div>
          <div class="saveButton">
            <el-button type="primary" @click="clearRight()">清空右</el-button>
          </div>
        </div>
        <div style="display: flex">
          <div>
            <el-input
              type="textarea"
              style="width: 30rem"
              :autosize="{ minRows: 6, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="copyText"
            >
            </el-input>
          </div>
          <div
            style="
              margin-left: 30px;
              display: flex;
              flex-direction: row;
              width: 30rem;
              flex-wrap: wrap;
            "
          >
            <div
              v-for="(item, index) in buttonList"
              :key="index"
              style="margin-left: 20px"
            >
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content">
                  {{ item.text }}
                </div>
                <el-button type="danger" v-clipboard:copy="item.text"
                  >复制 {{ item.number }}</el-button
                >
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "1",
      input: "",
      content: "111",
      link: "",
      type: "",
      baidulink: "",
      psw: "",
      copyText: "",
      saveList: [],
      buttonList: [],
    };
  },
  created() {},
  mounted() {
    console.log(localStorage.getItem("saveList"));
    let list = JSON.parse(localStorage.getItem("saveList")) || [];
    console.log("list", list);
    if (list.length > 0) {
      this.buttonList = list;
      for (let i = 0; i < list.length; i++) {
        this.saveList.push(list[i].text);
      }
    }
  },
  methods: {
    search() {
      let url = "";
      if (this.radio == "1") {
        url = "https://www.baidu.com/s?wd=" + this.input;
        window.open(url, "_blank");
        this.input = "";
      } else if (this.radio == "2") {
        url = "https://www.google.com/search?q=" + this.input;
        window.open(url, "_blank");
        this.input = "";
      }
    },
    tip() {
      window.open("https://zhuanlan.zhihu.com/p/231936440", "_blank");
    },
    climb() {
      window.open("sublime://");
    },
    jump() {
      let url = " " + this.link;
      window.open(url, "_blank");
      this.link = "";
    },
    change() {
      if (this.type == "") {
        this.type = "danger";
      } else {
        this.type = "";
      }
    },
    baiduPanJump() {
      // https://pan.baidu.com/s/1eVufrM8ocTFEpDjGjDfnrA?pwd=7e51
      let links = this.baidulink.slice(-8);
      let ss = "";
      if (this.type == "") {
        ss = "s/";
      } else {
        ss = "";
      }
      console.log("ssss", ss);
      if (links.slice(0, 4) == "pwd=") {
        let url = "https://pan.baidu.com/" + ss + this.baidulink;
        window.open(url, "_blank");
      } else {
        let url =
          "https://pan.baidu.com/" + ss + this.baidulink + "?pwd=" + this.psw;
        window.open(url, "_blank");
      }
      this.baidulink = "";
      this.psw = "";
    },
    save() {
      this.saveList.push(this.copyText);
      this.copyText = "";
      this.saveButton();
    },
    saveButton() {
      this.buttonList = [];
      if (this.saveList.length < 0) {
        this.saveList = localStorage.getItem("saveList");
      }
      console.log("saveList", this.saveList);
      for (let i = 0; i < this.saveList.length; i++) {
        console.log(this.saveList[i]);
        let a = {};
        a.number = i + 1;
        a.text = this.saveList[i];
        this.buttonList.push(a);
      }
      console.log("buttonList", this.buttonList);
      localStorage.setItem("saveList", JSON.stringify(this.buttonList));
    },
    clearLeft() {
      this.copyText = "";
    },
    clearRight() {
      this.saveList = [];
      this.buttonList = [];
    },
    copy(text) {
      Event.clipboardData.setData("text/plain", text);
      Event.preventDefault;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 3rem;
  background-color: rgba(255, 255, 255, 0.8);
  flex: 1;
  height: 40rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .block {
    margin: 30px;
    margin-bottom: 0;
    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}
.left {
  text-align: left;
}
.baiduJump {
  margin-left: 10px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
}
.saveButton {
  margin-left: 15px;
}
</style>
